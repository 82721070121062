<template>
  <div>
    <v-snackbar absolute top :timeout="4000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"> Editar {{ priceList.name }} </span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p>Descripción</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese la descripción"
                v-model="priceList.description"
              />
            </v-col>

            <v-col cols="12" md="6">
              <p>Tipo de descuento</p>
              <v-autocomplete
                v-model="priceList.isFixed"
                :items="amountType"
                placeholder="Seleccione tipo de monto"
                outlined
                item-value="value"
                item-text="text"
                rounded
                class="mt-6"
              />
            </v-col>

            <v-col cols="12" md="6">
              <p>Monto del descuento</p>
              <input
                class="control-input control-input-number"
                type="number"
                placeholder="Ingrese el monto"
                v-model.number="priceList.amount"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="priceList.active"
                  label="Lista activa "
                ></v-switch>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row align="center" justify="end">
                <v-btn
                  v-if="type == 'new'"
                  @click="saveList()"
                  class="save-btn mt-4"
                  color="primary"
                >
                  Guardar
                </v-btn>
                <v-btn
                  v-else
                  @click="updateList()"
                  class="save-btn mt-4"
                  color="primary"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";

export default {
  name: "new-edit-discount",
  props: ["type", "originalPriceLists"],
  components: {
    lottie: Lottie
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      snackbar: false,
      snackbarText: "",
      addProductDialog: false,
      dates: [],
      saving: false,
      loading: true,
      randomKey: 0,
      priceList: {
        is: true,
        amount: 0,
        // code: '',
        createdAt: "",
        createdBy: this.$store.state.user[".key"],
        name: "",
        description: "",
        // isCurrent: false,
        deleted: false,
        isFixed: true,
      },
      headers: [
        {
          text: "Producto",
          value: "name",
        },
        {
          text: "Precio original",
          value: "originalPrice",
        },
        {
          text: "Nuevo precio",
          value: "newPrice",
        },
        {
          value: "options",
          align: "end",
        },
      ],
      amountType: [
        { text: "Porcentual", value: false },
        { text: "Fijo", value: true },
      ],
      businesses: [],
      time: {
        startsAt: "00:00:01",
        endsAt: "23:59:00",
        startsis: false,
        endsis: false,
      },
      menuStartsAt: false,
      menuEndsAt: false,
      dates: ["", ""],
      menu: [false, false],
    };
  },
  async mounted() {
    if (this.type == "edit") {
      this.priceList = Object.assign({}, this.originalPriceLists);
    }
  },

  computed: {
    ...mapState(["selectedBusiness"]),

    validate() {
      if (!this.priceList.name || !this.priceList.amount) {
        this.snackbarText = "Debe completar el nombre y monto del descuento";
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },

    generateTimestamp() {
      return new Promise((resolve, reject) => {
        let date = this.dates[0].split("-");
        let time = this.time.startsAt.split(":");
        this.discount.dateStart = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt(time[0]),
            parseInt(time[1]),
            parseInt(time[2])
          )
        );
        date = this.dates[1].split("-");
        time = this.time.endsAt.split(":");
        this.discount.dateEnd = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt(time[0]),
            parseInt(time[1]),
            parseInt(time[2])
          )
        );
        resolve(true);
      });
    },
  },
  methods: {
    moment,
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    async saveList() {
      if (this.validate) {
        this.priceList.createdAt = new Date();
        this.priceList.products = [];
        this.saving = true;

        db.collection(`businesses/${this.selectedBusiness[".key"]}/priceLists`)
          .add(this.priceList)
          .then((ref) => {
            this.saving = false;
            this.$emit("success", "Descuento creado exitosamente.");
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
    async updateList() {
      if (this.validate) {
        this.saving = true;

        let data = {};
        data[`priceList${this.priceList.listPosition}IsActive`] =
          this.priceList.active;
        data[
          `priceList${this.priceList.listPosition}Name`
        ] = `priceList${this.priceList.listPosition}`;
        data[`priceList${this.priceList.listPosition}IsFixed`] =
          this.priceList.isFixed;
        data[`priceList${this.priceList.listPosition}Amount`] =
          this.priceList.amount;
        data[`priceList${this.priceList.listPosition}Description`] =
          this.priceList.description;

        db.collection(`businesses`)
          .doc(this.selectedBusiness[".key"])
          .update(data)
          .then((ref) => {
            this.saving = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    }
  },
};
</script>

<style>
.category-color {
  -webkit-appearance: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  height: 45px !important;
  cursor: pointer;
}

.category-color::-webkit-color-swatch {
  border-radius: 50px;
  padding: 0;
}

.category-color::-webkit-color-swatch-wrapper {
  border-radius: 50px;
  padding: 0;
}

.select-city {
  border-radius: 50px;
  padding: 0;
}

.control-input {
  border-color: rgba(117, 117, 117, 0.582);
}

.ad-img-promo {
  max-width: 100%;
  min-width: 100%;
  max-height: 250px;
  border-radius: 20px;
  cursor: pointer;
  object-fit: cover;
}

.img-container-promo {
  max-width: 100%;
  min-width: 100%;
  max-height: 200px;
  min-height: 200px;
  cursor: pointer;
  background-color: #92929221;
  border: 1px solid #80808062;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.time-picker .ant-time-picker-input {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  color: black;
  height: 44px;
}

.upload {
  display: none;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
  color: black !important;
  font-weight: bold;
}

.v-tab--is {
  border-bottom: 4px solid #f06a25 !important;
}
</style>
