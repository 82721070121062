import { render, staticRenderFns } from "./price-lists.vue?vue&type=template&id=3d260bd4&scoped=true"
import script from "./price-lists.vue?vue&type=script&lang=js"
export * from "./price-lists.vue?vue&type=script&lang=js"
import style0 from "./price-lists.vue?vue&type=style&index=0&id=3d260bd4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d260bd4",
  null
  
)

export default component.exports